<template>
    <v-main>
        <navbar />
        <h1>Miscellaneous</h1>
    </v-main>
</template>

<script>
    import Navbar from '@/components/Navbar'

    export default {
        name: 'Miscellaneous',

        components: {
            Navbar,
        },
    }
</script>
